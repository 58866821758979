.root {
  position: relative;
}

.noneMargin {
  margin-top: 0;
  margin-bottom: 0;
}
.denseMargin {
  margin-top: 8px;
  margin-bottom: 8px;
}
.normalMargin {
  margin-top: 16px;
  margin-bottom: 16px;
}

.loader {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}
