.root {
  padding-left: 16px;
  padding-right: 16px;
}
.root:not(:first-child) {
  padding-top: 0;
}
@media (min-width: 600px) {
  .root {
    padding-left: 24px;
    padding-right: 24px;
  }
}
