/* cyrillic-ext */
@font-face {
  font-family: '__Mulish_1ed915';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/5174d48889eafebe-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Mulish_1ed915';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/66c89cdb3c9b0c3f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Mulish_1ed915';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/d0d902c17c06e6a9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Mulish_1ed915';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/8c7a0a1575b90f43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Mulish_1ed915';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url(/_next/static/media/f7040064460a9b21-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Mulish_Fallback_1ed915';src: local("Arial");ascent-override: 96.56%;descent-override: 24.02%;line-gap-override: 0.00%;size-adjust: 104.08%
}.__className_1ed915 {font-family: '__Mulish_1ed915', '__Mulish_Fallback_1ed915';font-style: normal
}

@font-face {
  font-family: 'brand';
  font-display: auto;
  font-weight: normal;
  font-style: normal;
  src: url('/fonts/N-Gage.ttf') format('truetype');
}

.Button_root__1_rwX {
  position: relative;
}

.Button_noneMargin__pUgCh {
  margin-top: 0;
  margin-bottom: 0;
}
.Button_denseMargin__IS1p_ {
  margin-top: 8px;
  margin-bottom: 8px;
}
.Button_normalMargin__cqUDR {
  margin-top: 16px;
  margin-bottom: 16px;
}

.Button_loader__41_Oz {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.DialogFullScreen_content__N4bLh {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.IconButton_loading__Bv7Ji {
  position: relative;
}
.IconButton_circularProgress__jLoiV {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.DialogModal_fullWidth__YeV5h {
  width: 100%;
}
.DialogModal_fullHeight__dDSjU {
  height: 100%;
}
.DialogModal_paper__7ERbp {
  overflow: visible;
}

.DialogModal_icon__wMuNT {
  position: absolute;
  right: -52px;
  top: 12px;
  border-radius: 0 4px 4px 0;
  box-shadow: inset 6px 0px 20px -8px rgba(0, 0, 0, 0.5);
}

.DialogModal_container__TH23w {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 64px);
}
.DialogModal_content__g150h {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.DialogContent_root__XPRCN {
  padding-left: 16px;
  padding-right: 16px;
}
.DialogContent_root__XPRCN:not(:first-child) {
  padding-top: 0;
}
@media (min-width: 600px) {
  .DialogContent_root__XPRCN {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.TextField_textfield__gbgAt .MuiOutlinedInput-root input {
  box-shadow: unset;
  -webkit-box-shadow: unset;
  -webkit-text-fill-color: currentColor;
  caret-color: currentColor;
}

.useSignIn_forgottenPasswordLink__ZSj_9 {
  cursor: pointer;
}
.useSignIn_forgottenPasswordLink__ZSj_9:hover {
  text-decoration: underline;
}

