.fullWidth {
  width: 100%;
}
.fullHeight {
  height: 100%;
}
.paper {
  overflow: visible;
}

.icon {
  position: absolute;
  right: -52px;
  top: 12px;
  border-radius: 0 4px 4px 0;
  box-shadow: inset 6px 0px 20px -8px rgba(0, 0, 0, 0.5);
}

.container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 64px);
}
.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
